import dudekImage from "../assets/images/emp_dudek.png";
import danilchankaImage from "../assets/images/emp_danilchanka.png";
import fengImage from "../assets/images/emp_feng.png";
import karinImage from "../assets/images/emp_karin.png";
import jasonImage from "../assets/images/emp_jason.png";

const people = [
  {
    name: "Peter Dudek, Ph.D.",
    title: "President",
    dataTarget: "peterModal",
    image: dudekImage,
    paraOne:
      "Peter Dudek has over 15 years of experience in the life sciences industry spanning research, company building, corporate and traditional venture, across a broad range of therapeutic areas and modalities. He currently serves as President and Managing Partner of MRL Ventures Fund (MRLV) and is responsible for its investments in ADRx, Ambagon, InduPro, Insamo, Mozart, Photys and Tallac. Past board involvements include Carisma Therapeutics (NASDAQ: CARM), Caraway Therapeutics (acquired by Merck & Co., Inc., Rahway, NJ, USA), Hotspot Therapeutics, Imago Biosciences (acquired by Merck & Co., Inc., Rahway, NJ, USA), Kymera Therapeutics (NASDAQ: KYMR), Lava Therapeutics (NASDAQ: LAVA), LifeMine Therapeutics, Tidal Therapeutics (acquired by Sanofi) and Xilio Therapeutics (NASDAQ: XLO). Prior to MRLV, he was a Principal with Wellington Partners, a European venture fund with more than $1B under management. He has held roles at Entrepreneurs Fund and at the corporate venture arm of Novartis. Dr. Dudek received his B.Sc. with Honors from the University of British Columbia and was a research associate at the BC Centre for Disease Control. He obtained his Ph.D. from the University of Geneva and was a Postdoctoral Fellow at the University of Oxford."
  },
  {
    name: "Karin Kleinhans, Ph.D.",
    title: "Partner",
    dataTarget: "karinModal",
    image: karinImage,
    paraOne:
      "Karin has over 10 years of experience in the life science industry across research, IP law, venture capital and private equity. She is currently a Partner at MRLV and serves as a Director on the boards of Anavo Therapeutics, ATB Therapeutics and VectorY Therapeutics, and as an Observer on the board of Hotspot Therapeutics.",
    paraTwo:
      "Prior to becoming part of the MRLV team, she was a Partner at EQT, a global purpose-driven private equity firm, and part of its life sciences focused team. Prior to that, she was a Partner at LSP (now EQT Life Sciences), an Amsterdam, Munich and Boston based leading European healthcare focused investment firm with more than $3.2B under management where she focused on venture capital investments across a broad range of life science companies including: Cardior Pharmaceuticals (acquired by Novo Nordisk), Ariceum Therapeutics, T-knife Therapeutics, Endotronix (acquired by Edwards Lifesciences), Artios Pharma, Hotspot Therapeutics, Nkarta (NASDAQ: NKTX) and Amylyx (NASDAQ: AMLX). Before this, she worked in intellectual property law and was a scientist at the Max-Planck Department for Stem Cell Aging. She received her PhD from the International Graduate School in Molecular Medicine in Ulm funded by the Excellence Initiative of the German Federal and States Governments. She was awarded her Bachelor’s and Master’s Degree in Molecular Medicine where she combined medical studies with scientific research training."
  },
  {
    name: "Olga Danilchanka, Ph.D.",
    title: "Partner",
    dataTarget: "olgaModal",
    image: danilchankaImage,
    paraOne:
      "Since joining MRL Ventures Fund in 2018, Olga has focused on preclinical stage investments, championing companies that harness cutting-edge biology to devise therapeutic solutions in areas of significant unmet medical need. Currently, she serves as a Director on the board of Therini Bio and as an Observer on the boards of Axonis Therapeutics, Ambagon Therapeutics, and PAQ Therapeutics. Olga's previous board experiences include Eyebiotech Limited (acquired by Merck & Co., Inc., Rahway, NJ, USA), HotSpot Therapeutics, Lava Therapeutics (NASDAQ: LAVA), and Caraway Therapeutics (acquired by Merck & Co., Inc., Rahway, NJ, USA). Before her work with MRLV, Olga expanded her operational and scientific expertise at both Epiva Biosciences, a Flagship Pioneering newco, and the MRL Cambridge Exploratory Science Center, a subsidiary of Merck & Co., Inc., Rahway, NJ, USA. She holds a PhD from the University of Alabama at Birmingham and completed her postdoctoral training at Harvard Medical School. "
  }
];

export default people;
